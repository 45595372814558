import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { PLAN_CALENDAR } from '@atlassian/jira-capabilities/src/constants.tsx';
import { setCapability } from '@atlassian/jira-capabilities/src/services/active-capability-tracker/index.tsx';
import { createChromelessEntryPoint } from '@atlassian/jira-create-route-entry-point';
import { componentWithFG } from '@atlassian/jira-feature-gate-component';
import { fg } from '@atlassian/jira-feature-gating';
import { createEntry } from '@atlassian/jira-route-entry';
import { createLayout } from '@atlassian/jira-route-layout';
import {
	advancedRoadmapsPlanPageInitialChecksResource,
	advancedRoadmapsReduxStoreStateResource,
} from '@atlassian/jira-router-resources-advanced-roadmaps-plan/src/services/index.tsx';
import { themePreferenceResource } from '@atlassian/jira-router-resources-theme-preference/src/controllers/resource-erai-1615-old/index.tsx';
import { softwarePlanCalendarEmbedRoute } from '@atlassian/jira-router-routes-advanced-roadmaps-routes/src/ui/embed';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import { CalendarSkeleton } from '@atlassian/jira-skeletons/src/ui/calendar/index.tsx';
import type PlanCalendarType from '@atlassian/jira-spa-apps-advanced-roadmaps-plan/src/ui/plan-calendar';
import { calendarEntryPoint } from '@atlassian/jira-spa-apps-advanced-roadmaps-plan/src/ui/plan-calendar/entrypoint';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';
import type { Route } from '@atlassian/react-resource-router';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyPlanCalendarEmbed = lazyForPaint<typeof PlanCalendarType>(
	() =>
		markAsProductBundleReady(
			import(
				/* webpackChunkName: "async-plan-calendar-embed", jiraSpaEntry: "async-plan-calendar-embed" */ '@atlassian/jira-spa-apps-advanced-roadmaps-plan/src/ui/plan-calendar'
			),
		),
	{ ssr: false },
);

const PlanCalendarEmbedPage = () => (
	<LazyPage Page={LazyPlanCalendarEmbed} pageId="plan-calendar-embed" shouldShowSpinner />
);

const planCalendarEmbedRouteEntry = createChromelessEntryPoint({
	main: calendarEntryPoint,
});

export const arjCalendarEmbedRouteEntry: Route = createEntry(softwarePlanCalendarEmbedRoute, () => ({
    ufoName: 'software-advanced-roadmaps-calendar-embed',
    component: componentWithFG('smart_links_for_plans', PlanCalendarEmbedPage, ErrorPagesNotFound),

    skeleton: () => {
		if (fg('smart_links_for_plans')) {
			return fg('platform_calendar_adoption_in_plans') ? <CalendarSkeleton /> : undefined;
		}
		return undefined;
	},

    entryPoint() {
		setCapability(PLAN_CALENDAR);
		if (fg('smart_links_for_plans')) {
			return fg('platform_calendar_adoption_in_plans') ? planCalendarEmbedRouteEntry : undefined;
		}
		return undefined;
	},

    layout: createLayout({
		isChromeless: true,
	}),

    resources: [
		advancedRoadmapsPlanPageInitialChecksResource,
		advancedRoadmapsReduxStoreStateResource,
		themePreferenceResource,
	],

    preloadOptions: {
		earlyChunks: ['advanced-roadmaps-early-entry'],
	},

    meta: {
		capability: PLAN_CALENDAR,
	},

    forPaint: [LazyPlanCalendarEmbed]
}));
